<template>
  <div class="main-view">
    <div class="main-view-bg">
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='110px'
        label-position='top'
        :model='form.data'
        :rules='form.rules'
        size="small"
        hide-required-asterisk
      >
          <el-alert
            title="销售金额如需提现，系统将打款至以下账户，请您仔细核对并尽快完善相关信息！"
            type="warning"
            show-icon
            :closable="false"
            style="width: 600px;margin-bottom:20px;border: solid 1px #e6a23c;">
          </el-alert>
         <el-row :gutter="50" style="width:80%;">
          <el-col :span="8" >
            <el-form-item label="账户名称" prop="bankaccount">
              <el-input
                v-model="form.data.bankaccount"
                placeholder="请输入"
                clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开户银行" prop="bankname">
              <el-input
                v-model="form.data.bankname"
                placeholder="请输入"
                clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="银行卡号" prop="bankno">
              <el-input
                v-model="form.data.bankno"
                placeholder="请输入"
                clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col style="display:flex;justify-content: center;margin-top:20px;"  :span="24">
            <el-button type="primary" :loading="form.loading" @click='confirm'>保存信息</el-button>
          </el-col>
        </el-row>

        <el-row :gutter="50" class="licence_wrap">
          <el-col :span="24" style="margin-bottom: 20px;font-weight:bold;">开票信息</el-col>
          <el-col :span="8">
            <el-form-item label="企业名称">
              <el-input
                v-model="invoice.company"
                placeholder="-----"
                :disabled="true">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="纳税人识别号">
              <el-input
                v-model="invoice.licence"
                placeholder="-----"
                :disabled="true">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="注册地址">
              <el-input
                v-model="invoice.address"
                placeholder="-----"
                :disabled="true">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开户行">
              <el-input
                v-model="invoice.bank"
                placeholder="-----"
                :disabled="true">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="账号">
              <el-input
                v-model="invoice.bankno"
                placeholder="-----"
                :disabled="true">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
       
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SettleAccount',
  data() {
    return {
      loading: false,
      status: 1,
      form: {
        data: {
          bankno: '',
          bankname: '',
          bankaccount: '',
        },
        rules: {
          bankno: [{ required: true, message:'必填项', trigger: 'change' }],
          bankname: [{ required: true, message:'必填项', trigger: 'change' }],
          bankaccount: [{ required: true, message:'必填项', trigger: 'change' }],
        },
      },
      invoice: {}
    }
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$http.get('/company/settlement/account',{params: {}}).then(res => {
        if(res.code == 1) {
          this.form.data = res.data;
          this.invoice = res.data.invoice;
        }
      })
    },
    confirm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params = {
            bankno: this.form.data.bankno,
            bankname: this.form.data.bankname,
            bankaccount: this.form.data.bankaccount,
          }
          this.$http.post('/company/settlement/editAccount', _params).then(res => {
            if(res.code == 1) {
              this.$message.success('保存成功！')
              this.getInfo();
            }
          })
        }
      })
    },
  }
}
</script>
<style scoped>
.main-view {
  margin: 0 !important;
}
.main-view-bg {
  background: #fff;
  margin: 20px;
  border-radius: 6px;
  padding: 20px 32px;
  overflow: auto;
}
.licence_wrap {
  width: 90%;
  background: #FCFCFC;
  border: solid 1px #E5E5E5;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 10px;
  margin-top:50px;
  margin-left: 0 !important;
}
.licence_foot {
  background: #fff;
  padding: 10px 20px;
  margin-top: auto;
  text-align: right;
}
.flex {
  display: flex;
}
.space-between {
  width: 100%;
  justify-content: space-between;
}
.hint {
  font-size: 14px;
  color: #F35149;
}
.imgbtn {
  width: 160px;
  border: solid 1px #007DF2;
  color: #007DF2;
  border-radius: 2px;
  height: 36px;
  line-height: 36px;
}
.staff-from {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

.imageWrap ::v-deep .el-upload--picture-card {
  border: none;
  width: 104px;
  height: 104px;
}
.imageWrap ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 104px;
  height: 104px;
}
.imageWrap ::v-deep .el-range-editor--small .el-range-separator {
  width: 20px;
}

.staff-from::-webkit-scrollbar {
  display: none;
}
.label {
  color: #999999;
  font-size: 14px;
}
.label-hint {
  font-size: 14px;
  color: rgb(153, 153, 153);
  position: absolute;
  top: -33px;
  left: 62px;
}
.btnPrimary {
  background: #7EBCF7;
  color: #fff;
  text-align: center;
  width: 160px;
  height: 38px;
  line-height: 38px;
  border-radius: 3px;
}
.btnWarning {
  background: #FFF3EE;
  color: #FF5D04;
  text-align: center;
  width: 180px;
  height: 38px;
  line-height: 38px;
  border-radius: 3px;
}
</style>